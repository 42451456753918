import {Subscription} from "rxjs";

/**
 * Reasons, why a future interaction conversation started by an agent cannot be picked up by the visitor
 * Must be kept in sync with the middleware so it can hold all reasons properly
 */
export enum AbortReasons {
    ALREADY_CONVERSATION_ACTIVE = "ALREADY_CONVERSATION_ACTIVE",
    HAS_ALREADY_FUTURE_INTERACTION_CONVERSATION_PENDING = "HAS_ALREADY_FUTURE_INTERACTION_CONVERSATION_PENDING",
    MOBILE_SCREEN = "MOBILE_SCREEN",
    USER_CLOSED = "USER_CLOSED"
}

export type Logger = Record<"info" | "warn" | "error", (...logData: unknown[]) => void>;
