import {
    Configuration,
    ConversationType,
    NewConversationInterceptor,
} from "@unblu/floating-js-api";
import unblu from "@unblu/floating-js-api";

import {
    observeFutureInteraction,
    registerFutureInteractionPopupCloseListener,
    registerFutureInteractionPopupOpenListener, resetFutureInteraction, showFutureInteractionUI,
    unregisterFutureInteractionPopupCloseListener,
    unregisterFutureInteractionPopupOpenListener
} from "./future-interaction";

import {isMobileScreen} from "./unblu-integration-util";
import {NEVER} from "rxjs";

declare global {
    /**
     * Global variable `unbluIntegrationComponent` providing all functionality of this module
     */
    interface Window {
        unbluIntegrationComponent: UnbluIntegrationComponent;
        unbluIntegrationComponentLogLevel: boolean;
    }
}

const defaultApiKey = "MZsy5sFESYqU7MawXZgR_w";
const installationProvidedApiKey = "@@unblu.api.key@@";

// const defaultUnbluEntryPath = undefined;
const defaultUnbluEntryPath = "/ap/ga/ub";
const installationProvidedUnbluEntryPath = "@@unblu.entrypath@@";

const defaultUnbluServerUrl = undefined;
const installationProvidedUnbluServerUrl = "@@unblu.server.url@@";

const defaultBaseScriptName = "unblu.integration.component";
const installationProvidedBaseScriptName = "@@unblu.integration.component.basename@@";

const customScripts = ["@@unblu.customscript.1@@"];

// const defaultLogLevel: string = "debug";
const defaultLogLevel: string = "";
const installationProvidedLogLevel: string = "@@unblu.uic.loglevel@@";

const defaultFipoNamedArea: string = "fipo-chat-conversation";
const installationProvidedFipoNamedArea: string = "@@unblu.fipo.namedarea@@";

const defaultEFinanceUserInfoURL: string = "/pfch/rest-okaut/api-vs/efinanceuserinfo";
const installationProvidedEFinanceUserInfoURL: string = "@@unblu.efinanceuserinfo.url@@";

const defaultFIBaseUrl = "/ap/ga/ub/fi";
const installationProvidedFIBaseUrl: string = "@@unblu.fiBaseUrl@@";

let conversationIsStarting: boolean = false;

let visitorLoggedRetrieved: Promise<boolean> | null = null;

type Logger = Record<"info" | "warn" | "error", (...logData: unknown[]) => void>;
let log: Record<"info" | "warn" | "error", (...logData: unknown[]) => void> = {
    info: (...logData: unknown[]) => logProvider("info", logData),
    warn: (...logData: unknown[]) => logProvider("warn", logData),
    error: (...logData: unknown[]) => logProvider("error", logData)
};
let logProvider = (level: "info" | "warn" | "error", ...logData: unknown[]) => {
    if (defaultLogLevel === "debug" || installationProvidedLogLevel === "debug" || window.unbluIntegrationComponentLogLevel || document.location.href.indexOf("debugUIC") !== -1) {
        switch(level) {
            case "info":
                console.info("UIC", ...logData);
                break;
            case "warn":
                console.warn("UIC", ...logData);
                break;
            case "error":
                console.error("UIC", ...logData);
                break;
        }
    }
};

/**
 * Check whether an Unblu session of any kind is currently active
 *
 * @returns {Promise<boolean>} `true`, if an Unblu session is active, `false` otherwise
 */
async function isSessionActive(): Promise<boolean> {
    log.info("isSessionActive called");

    const hasActiveConversation = async (): Promise<boolean> => {
        await configApi();
        let _api = await unblu.api.initialize();
        const isActive = (await _api.getActiveConversation() !== null);
        return isActive;
    };

    const checkForActiveSessionAfterPageLoad = async (): Promise<boolean> => {
        return new Promise(((resolve, reject) => {
            if (document.readyState === "complete") {
                if (unblu.api.getApiState() === "INITIAL") {
                    resolve(false);
                    return;
                }

                resolve(hasActiveConversation());
            } else {
                window.addEventListener("load", (event) => {
                    if (unblu.api.getApiState() === "INITIAL") {
                        resolve(false);
                        return;
                    }

                    resolve(hasActiveConversation())
                });
            }
        }));
    };

    const isActive = await checkForActiveSessionAfterPageLoad();
    log.info("isSessionActive result: " + isActive);

    return isActive;
}

/**
 * Open the Unblu individual UI (which will show different content depending on the visitors situation)
 * Usually used after `isSessionActive()` calls with result `true` in order to just show
 * the individual UI (again) instead of starting a new chat or co-browsing session
 */
async function openIndividualUi(): Promise<void> {
    log.info("openIndividualUi called");

    await configApi();
    let _api = await unblu.api.initialize();
    await _api.ui.openIndividualUi();
}

/**
 * Checks for the given visitor location in the browser (e.g. on video landing page) whether
 * an agent would be available when the visitor would start a chat at the moment of the check.
 */
async function isAgentAvailable(): Promise<boolean> {
    log.info("isAgentAvailable called");

    await configApi();
    let _api = await unblu.api.initialize();
    const isAgentAvailable = await _api.isAgentAvailable();

    log.info("isAgentAvailable result: " + isAgentAvailable);

    return isAgentAvailable;
}

/**
 * Start a session with a given PIN (e.g. Live Support / Co-Browsing)
 *
 * @param pin The PIN code the visitor received from an agent to share the browser window with
 * @returns {Promise<string>} session ID (Unblu internally called "conversation id")
 */
async function startWithPin(pin: string): Promise<string> {
    log.info("startWithPin");

    if (conversationIsStarting) {
        log.info("startWithPin: aborted as another conversation is already starting");
        return Promise.reject("Another conversation is already starting");
    }

    conversationIsStarting = true;

    let conversationId = null;
    try {
        await configApi();
        let _api = await unblu.api.initialize();

        let conversation = await _api.getActiveConversation();
        if (conversation === null) {
            conversation = await _api.joinConversation(pin);

            log.info("startWithPin result: " + conversation.getConversationId());
        } else {
            log.info("startWithPin result: No action (session already active)");
        }

        conversationId = conversation.getConversationId();
    } finally {
        conversationIsStarting = false;
    }

    return conversationId;
}

/**
 * Start a chat session
 *
 * @returns {Promise<string>} session ID (Unblu internally called "conversation id")
 */
async function openUnbluUI(): Promise<string> {
    log.info("startChat called");

    if (conversationIsStarting) {
        log.info("startChat: aborted as another conversation is already starting");
        return Promise.reject("Another conversation is already starting");
    }

    conversationIsStarting = true;

    let conversationId = null;
    try {
        await configApi();
        let _api = await unblu.api.initialize();

        let conversation = await _api.getActiveConversation();
        if (conversation === null) {
            let availabilitystate = await _api.isAgentAvailable();
            if (availabilitystate) {
                conversation = await _api.startConversation(ConversationType.CHAT_REQUEST);

                log.info("startOnlineChat result: " + conversation.getConversationId());
            } else {
                conversation = await _api.startConversation(ConversationType.OFFLINE_CHAT_REQUEST);

                log.info("startOfflineChat result: " + conversation.getConversationId());
            }
        } else {
            log.info("startChat result: no action (session already active)");
        }

        await _api.ui.openIndividualUi();

        conversationId = conversation.getConversationId();
    } finally {
        conversationIsStarting = false;
    }

    return conversationId;
}

/**
 * Setup a "new conversation" event interceptor
 * This method is "lazy" in the sense that it checks the Unblu API whether it's already initialized. If it is,
 * the interceptor is forwarded straight away. If it's not, it is buffered until the API initializes.
 *
 * @returns {Promise<void>} resolves (without result), once the interceptor has been set.
 */
async function setNewConversationInterceptor(interceptor: NewConversationInterceptor): Promise<void> {
    log.info("setNewConversationInterceptor called");

    await configApi();
    let _api = await unblu.api.initialize();
    return _api.setNewConversationInterceptor(interceptor);
}

/**
 * Load an external javascript and return promise. Resolves when javascript is loaded, rejects when an error occurred.
 *
 * @param scriptUrl URL of the javascript to load (must work in src attribute in context of this script)
 */
async function loadScript(scriptUrl: string): Promise<void> {
    log.info("loadScript called for " + scriptUrl);

    return new Promise<void>((resolve, reject) => {
        let s = document.createElement("script");
        s.setAttribute("src", scriptUrl);
        s.setAttribute("defer", "defer");
        s.onload = (event) => resolve();
        s.onerror = (event) => reject((event as ErrorEvent).message);
        document.head.appendChild(s);
    });
}

/**
 * Load all scripts listed in the customScript array not starting with @ (@ is the placeholder marker for
 * the Postfinance deployment job)
 */
async function loadCustomScripts(): Promise<void> {
    log.info("loadCustomScripts called");

    const loadingScripts: Promise<void>[] = [];

    customScripts.forEach(customScript => {
        if (!customScript.startsWith("@")) {
            loadingScripts.push(loadScript(customScript));
        }
    });

    await Promise.all(loadingScripts);
}

/**
 * Check if the current browser is smaller than a mobile device would be (i.e. it _is_ a mobile device or the browser window is very small)
 * If it is, in the event of an active conversation, make sure the Unblu individual UI is collapsed by default.
 */
async function customMobileHandling(): Promise<void> {
    log.info("customMobileHandling called");

    let mobileScreen = await isMobileScreen();
    if (mobileScreen) {
        if (await isSessionActive()) {
            log.info("customMobileHandling detected active conversation and mobile device: collapsing UI");

            await configApi();
            const _api = await unblu.api.initialize();
            await _api.ui.collapseIndividualUi();
        }
    }
}

function getIntegrationScriptBaseName(): string {
    let integrationScriptBaseName = defaultBaseScriptName;
    if (!installationProvidedBaseScriptName.startsWith("@")) {
        integrationScriptBaseName = installationProvidedBaseScriptName;

        log.info("Using integrationScriptBaseName: " + integrationScriptBaseName);
    }

    return integrationScriptBaseName;
}

function getIntegrationScriptUrl(): string | null {
    let url = null;
    let ourScript = document.querySelector("script[src*='" + getIntegrationScriptBaseName() + "']");
    if (ourScript && ourScript.getAttribute("src")) {
        url = ourScript.getAttribute("src");
    }

    return url;
}

/**
 * Configure the Unblu JS API and in particular manage the api key by using a number of fallbacks:
 * * Search script tag with unblu-api-key as part of the src attribute, extract api key from there and use it. If not present:
 * * Check installationProvidedApiKey attribute, whether it contains a replaced (from deployment) value. If not present:
 * * Use the defaultApiKey
 */
async function configApi() {
    if (!unblu.api.isConfigurationNeeded()) {
        return;
    }

    let integrationScriptBaseName = getIntegrationScriptBaseName();
    log.info("Using integrationScriptBaseName: " + integrationScriptBaseName);

    // last fallback
    let apiKey = defaultApiKey;
    let unbluEntryPath: string | undefined = defaultUnbluEntryPath;
    let unbluServerUrl: string | undefined = defaultUnbluServerUrl;

    // override fallback with api key provided - if provided
    if (!installationProvidedApiKey.startsWith("@")) {
        apiKey = installationProvidedApiKey;
        log.info("Overriding apiKey with installation param: " + apiKey);
    }

    if (!installationProvidedUnbluEntryPath.startsWith("@")) {
        unbluEntryPath = installationProvidedUnbluEntryPath;
        log.info("Overriding unbluEntryPath with installation param: " + unbluEntryPath);
    }

    if (!installationProvidedUnbluServerUrl.startsWith("@")) {
        unbluServerUrl = installationProvidedUnbluServerUrl;
        log.info("Overriding unbluServerUrl with installation param: " + unbluServerUrl);
    }

    // uses webpack DefinePlugin to create the process.env.NODE_ENV variable for development builds
    // webpack will drop this whole part or production builds
    if (process.env.NODE_ENV === 'development') {
        // override with script request hash - if provided
        let url = getIntegrationScriptUrl();
        if (url) {
            let match = url.match(/x-unblu-apikey=([^&]+)/);
            if (match && match.length === 2 && match[1]) {
                apiKey = decodeURIComponent(match[1]);
                log.info("Overriding apiKey with script param: " + apiKey);
            }

            match = url.match(/x-unblu-entrypath=([^&]+)/);
            if (match && match.length === 2 && match[1]) {
                unbluEntryPath = decodeURIComponent(match[1]);
                log.info("Overriding unbluEntryPath with script param: " + unbluEntryPath);
            }

            match = url.match(/x-unblu-serverurl=([^&]+)/);
            if (match && match.length === 2 && match[1]) {
                unbluServerUrl = decodeURIComponent(match[1]);
                log.info("Overriding unbluServerUrl with script param: " + unbluServerUrl);
            }
        }
    }

    log.info("Configuring Unblu API with apiKey: " + apiKey + ", entryPath: " + unbluEntryPath + ", serverUrl: " + unbluServerUrl);
    let config: Configuration = {
        apiKey: apiKey
    };

    if (unbluEntryPath) {
        config.entryPath = unbluEntryPath;
    }

    if (unbluServerUrl) {
        config.serverUrl = unbluServerUrl;
    }

    // leave if we can't configure anymore anyway
    if (!unblu.api.isConfigurationNeeded()) {
        return;
    }

    if (await isVisitorLoggedIn()) {
        let namedArea = defaultFipoNamedArea;
        if (!installationProvidedFipoNamedArea.startsWith("@")) {
            namedArea = installationProvidedFipoNamedArea;
            log.info("Overriding namedArea with installation param: " + namedArea);
        }

        if (namedArea) {
            config.namedArea = namedArea;
        }
    }

    // [Sebastian] the state sometimes changes during the above await, this aborts, it results into a race condition without!
    if (!unblu.api.isConfigurationNeeded()) {
        return;
    }

    log.info("Configuring Unblu API with this final config:", config);

    unblu.api.configure(config);
}

function fireUnbluIntegrationComponentReadyEvent() {
    log.info("fireUnbluIntegrationComponentReadyEvent called");

    let event = new Event("unbluIntegrationComponentReady");
    window.dispatchEvent(event);
}

/**
 * Special function to determine whether visitor is logged in or not
 */
async function isVisitorLoggedIn(): Promise<boolean> {
    // uses webpack DefinePlugin to create the process.env.NODE_ENV variable for development builds
    // webpack will drop this whole part or production builds
    if (process.env.NODE_ENV === 'development') {
        // override with script request hash - if provided
        let url = getIntegrationScriptUrl();
        if (url) {
            let match = url.match(/x-unblu-fi-always-logged-in=([^&]+)/);
            if (match && match.length === 2 && match[1]) {
                const alwaysLoggedIn = decodeURIComponent(match[1]);
                if (alwaysLoggedIn === "true") {
                    log.info("Pretending visitor is logged in");
                    return true;
                }
            }
        }
    }

    const verifyLoggedIn = async () => {
        let efinanceUserInfoUrl = defaultEFinanceUserInfoURL;
        if (!installationProvidedEFinanceUserInfoURL.startsWith("@")) {
            efinanceUserInfoUrl = installationProvidedEFinanceUserInfoURL;
            log.info("Overriding efinanceUserInfoUrl with installation param: " + efinanceUserInfoUrl);
        }

        return fetch(efinanceUserInfoUrl)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                return { "status": "nodata" };
            })
            .then(data => data.status === "ok");
    }

    // the isLoggedIn call is asynchronous. It's possible that we are called again while we're waiting for
    // the first call to return a result. We have to treat this here. We don't need a second call, if a first
    // one is already in progress

    // check for visitor login state and configure named area accordingly
    visitorLoggedRetrieved = visitorLoggedRetrieved || verifyLoggedIn();
    return visitorLoggedRetrieved;
}

export interface UnbluIntegrationComponent {
    /**
     * Check whether an Unblu session of any kind is currently active
     *
     * @returns {Promise<boolean>} `true`, if an Unblu session is active, `false` otherwise
     */
    isSessionActive: () => Promise<boolean>;

    /**
     * Open the Unblu individual UI (which will show different content depending on the visitors situation)
     * Usually used after `isSessionActive()` calls with result `true` in order to just show
     * the individual UI (again) instead of starting a new chat or co-browsing session
     */
    openIndividualUi: () => Promise<void>;

    /**
     * Checks for the given visitor location in the browser (e.g. on video landing page) whether
     * an agent would be available when the visitor would start a chat at the moment of the check.
     */
    isAgentAvailable: () => Promise<boolean>;

    /**
     * Start a chat session
     *
     * @returns {Promise<string>} session ID (Unblu internally called "conversation id")
     */
    startChat: () => Promise<string>;

    /**
     * Show the future interaction UI
     *
     * Temporary diagnostic function
     */
    showFutureInteractionUI: (conversationId: string, agentAvatarUrl: string, agentName: string, firstMessage: string) => Promise<0>;

    /**
     * Start a session with a given PIN (e.g. Live Support / Co-Browsing)
     *
     * @param pin The PIN code the visitor received from an agent to share the browser window with
     * @returns {Promise<string>} session ID (Unblu internally called "conversation id")
     */
    startWithPin: (pin: string) => Promise<string>;

    /**
     * Setup a "new conversation" event interceptor
     * This method is "lazy" in the sense that it checks the Unblu API whether it's already initialized. If it is,
     * the interceptor is forwarded straight away. If it's not, it is buffered until the API initializes.
     *
     * @returns {Promise<void>} resolves (without result), once the interceptor has been set.
     */
    setNewConversationInterceptor: (interceptor: NewConversationInterceptor) => Promise<void>;

    /**
     * Register an event listener.
     * The listener is called, when the future interaction popup is about to be shown because either a new
     * future interaction outbound conversation has arrived or a navigation to a page has ahppened and a
     * future interaction outbound conversation was shown before and no inbound session is currently going on.
     *
     * @param {() => Promise<void>} listener called when future interaction popup is about to be shown. Unblu will hold
     * back showing the popup until all listeners promises have resolved or rejected.
     */
    registerFutureInteractionPopupOpenListener: (listener: () => Promise<void>) => void;

    /**
     * Unregister a previously registered listener.
     *
     * @param {() => void} listener The listener provided to {@link registerFutureInteractionPopupOpenListener} before
     */
    unregisterFutureInteractionPopupOpenListener: (listener: () => Promise<void>) => void;

    /**
     * Register an event listener.
     * The listener is called, when the future interaction popup has hidden because either the user has chosen to close
     * it, or chosen to start to join the future interaction outbound chat.
     * It is the responsibility of the listener code to check also with e.g.
     * {@link isSessionActive} whether a session is active or not
     *
     * @param {() => Promise<void>} listener called when future interaction popup has hidden
     */
    registerFutureInteractionPopupCloseListener: (listener: () => Promise<void>) => void;

    /**
     * Unregister a previously registered listener.
     *
     * @param {() => void} listener The listener provided to {@link registerFutureInteractionPopupCloseListener} before
     */
    unregisterFutureInteractionPopupCloseListener: (listener: () => void) => void;
}

export const unbluIntegrationComponent: UnbluIntegrationComponent = {
    isSessionActive: isSessionActive,
    openIndividualUi: openIndividualUi,
    isAgentAvailable: isAgentAvailable,
    startChat: openUnbluUI,
    startWithPin: startWithPin,
    showFutureInteractionUI: showFutureInteractionUI,
    setNewConversationInterceptor: setNewConversationInterceptor,
    registerFutureInteractionPopupOpenListener: registerFutureInteractionPopupOpenListener,
    unregisterFutureInteractionPopupOpenListener: unregisterFutureInteractionPopupOpenListener,
    registerFutureInteractionPopupCloseListener: registerFutureInteractionPopupCloseListener,
    unregisterFutureInteractionPopupCloseListener: unregisterFutureInteractionPopupCloseListener
};

// init when this script is loaded
(async function () {
    try {
        await loadCustomScripts();
        await customMobileHandling();

        // special reset on logout page
        if (/[?&]logout/.test(location.href)) {
            await resetFutureInteraction();
        }

        if (await isVisitorLoggedIn()) {
            let fiBaseUrl = defaultFIBaseUrl;

            // override fallback with fi base url provided - if provided
            if (!installationProvidedFIBaseUrl.startsWith("@")) {
                fiBaseUrl = installationProvidedFIBaseUrl;
                log.info("Overriding future interaction base url with installation param: " + fiBaseUrl);
            }

            // uses webpack DefinePlugin to create the process.env.NODE_ENV variable for development builds
            // webpack will drop this whole part or production builds
            if (process.env.NODE_ENV === 'development') {
                // override with script request hash - if provided
                let url = getIntegrationScriptUrl();
                if (url) {
                    let match = url.match(/x-unblu-fi-baseurl=([^&]+)/);
                    if (match && match.length === 2 && match[1]) {
                        fiBaseUrl = decodeURIComponent(match[1]);
                        log.info("Overriding future interaction base url with url hash param: " + fiBaseUrl);
                    }
                }
            }

            log.info("Visitor is logged in - starting to observe for future interaction");
            await observeFutureInteraction(
                log,
                configApi,
                fiBaseUrl,
                isSessionActive,
                isMobileScreen,
                NEVER,
                () => Promise.resolve(new URL(document.location.href))
            );
        }

        // always initialize the api
        await configApi();
        await unblu.api.initialize();

        fireUnbluIntegrationComponentReadyEvent();
    } catch (error) {
        log.error("Error during init of Unblu Integration Component", error);
    }
})();

